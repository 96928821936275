import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <div
      style={{
        display: "inline-block",
        marginTop: "80px",
        marginLeft: "10px",
        borderTop: "10px solid black",
        borderBottom: "10px solid black"
      }}
    >
      <h1
        style={{
          fontFamily: "Arial black",
          fontWeight: "800",
          background: "#997300",
          letterSpacing: "-5px",
          paddingRight: "10px",
          marginBottom: "0px",
          color: "black"
        }}
      >
        UNDER
      </h1>

      <h1
        style={{
          fontFamily: "Arial black",
          fontWeight: "800",
          background: "#997300",
          letterSpacing: "-5px",
          paddingRight: "10px",
          marginBottom: "0px",
          color: "black"
        }}
      >
        CONSTRUCTION
      </h1>
    </div>
  </Layout>
)

export default IndexPage
